import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  totalProperties: 0,
  walletProperties: 0,
  properties: [],
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    handleProperties: (state, action) => {
      state.totalProperties = action.payload;
    },
    handleWallet: (state, action) => {
      state.walletProperties = action.payload;
    },
    handleAllProperties: (state, action) => {
      state.properties = action.payload;
    },
  },
});

export const { handleProperties, handleWallet, handleAllProperties } =
  dashboardSlice.actions;

export default dashboardSlice.reducer;
