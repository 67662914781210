import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  csrInfo: null,
  csrPropertId: "",
  selectedCsrServices: [],
  csrServiceCreated: false,
  csrBalance: 0,
};

const csrSlice = createSlice({
  name: "csrSlice",
  initialState,
  reducers: {
    handleCsrProperty: (state, action) => {
      state.csrInfo = action.payload;
    },

    handleCsrId: (state, action) => {
      state.csrPropertId = action.payload;
    },
    handleSelecteCsr: (state, action) => {
      state.selectedCsrServices = action.payload;
    },
    handleCsrCreated: (state, action) => {
      state.csrServiceCreated = action.payload;
    },
    handleCsrBalnce: (state, action) => {
      state.csrBalance = action.payload;
    },
  },
});

export const {
  handleCsrId,
  handleCsrProperty,
  handleCsrBalnce,
  handleCsrCreated,
  handleSelecteCsr,
} = csrSlice.actions;

export default csrSlice.reducer;
