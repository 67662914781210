import React, { useState } from "react";
import axios from "axios";
import {
  CButton,
  CCard,
  CInput,
  CFormGroup,
  CLabel,
  CSelect,
} from "@coreui/react";
import * as Yup from "yup";
import { Formik } from "formik";
import { URL } from "../../../../config/config";
import swal from "sweetalert";
// import Loader from "../../views/layout/loader";
import { Link } from "react-router-dom";
import { CustomNewButton } from "../../../../containers/helper";
import { useDispatch } from "react-redux";
import {
  updateRegEmail,
  updateRegType,
  updateShowOtp,
} from "../../../../reduxConfig/slice/userSlice";

const P4bRegComps = () => {
  const dispatch = useDispatch();
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object({
    orgName: Yup.string().required("Organization name is required"),
    orgAddress: Yup.string().required("organization address is required"),
    industry: Yup.string().required("industry type is required"),
    subIndustry: Yup.string().required("Sub Industry type is required"),
    email: Yup.string().email().required("email address is required"),
    password: Yup.string().required("password is required"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("phone number is required"),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Password did not match"
    ),
    // estateType: Yup.string().required("password is required"),
  });

  const [selectedIndustry, setSelectedIndustry] = useState(null);

  const validationSchemaStep1 = Yup.object({
    orgName: Yup.string().required("Organization name is required"),
    orgAddress: Yup.string().required("Organization address is required"),
    industry: Yup.string().required("Industry type is required"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone number is required"),
  });

  const validationSchemaStep2 = Yup.object({
    email: Yup.string().email().required("Email address is required"),
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords did not match")
      .required("Confirm Password is required"),
    subIndustry: Yup.string().required("Sub Industry type is required"),
  });

  const businessOptions = [
    "Bank",
    "Oil",
    "Company",
    "Telecoms",
    "Insurance",
    "Office Complex",
    "Multinational",
  ];
  const mallOptions = ["Shopping Mall", "Market Mall", "Cinemas"];

  const hospitalityOptions = [
    "Hotel",
    "Restaurant",
    "Hospital",
    "School",
    "Church",
    "Mosque",
    "University",
  ];

  const [loading, setLoading] = useState(false);

  const diplomatOptions = ["Embassy", "Multinational Organization", "NGO"];

  const governmentOptions = ["Barracks", "Secretariat", "Government Agency"];

  const [step, setStep] = useState(1);

  return (
    <Formik
      initialValues={{
        email: "",
        phone: "",
        password: "",
        orgName: "",
        orgAddress: "",
        industry: "",
        subIndustry: "",
        confirmPassword: "",
        estateType: "",
      }}
      validationSchema={
        step === 1 ? validationSchemaStep1 : validationSchemaStep2
      }
      onSubmit={(values) => {
        console.log(values);
        if (step === 1) {
          setStep(2);
        } else {
          setLoading(true);
          axios
            .post(`${URL}/register`, {
              phone: values.phone,
              email: values.email,
              password: values.password,
              orgName: values.orgName,
              orgAddress: values.orgAddress,
              type: "P4B",
              // industry: values.industry,
            })
            .then((res) => {
              setLoading(false);
              if (res.data.responseCode === 0) {
                localStorage.setItem(
                  "authData",
                  JSON.stringify(res.data.userId)
                );
                sessionStorage.setItem("matchCode", values.email);
                localStorage.setItem(
                  "accountType",
                  JSON.stringify(res.data.accountType)
                );
                dispatch(updateShowOtp(true));
                dispatch(updateRegEmail(values.email));

                // window.location.href = "/verify-email";
              } else if (res.data.responseCode > 0) {
                swal("FAILED", res.data.responseMessage, "danger");
              }
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
            });
        }
      }}
    >
      {({ handleSubmit, handleChange, touched, values, errors }) => (
        <form onSubmit={handleSubmit}>
          {step === 1 && (
            <>
              <CFormGroup>
                <label className="custom-label-new">Organization Name</label>
                <input
                  className="custom-input-new"
                  type="text"
                  placeholder="Enter Organisation Name"
                  name="orgName"
                  value={values.orgName}
                  onChange={handleChange}
                />
                <small className="text-danger">
                  {touched.orgName && errors.orgName}
                </small>
              </CFormGroup>
              <CFormGroup>
                <label className="custom-label-new">Nature of business</label>
                <select
                  className="custom-select-new"
                  value={values.industry}
                  onChange={handleChange}
                  name="industry"
                >
                  <option value="">Select Industry Type</option>
                  <option value="business">Business</option>
                  {/* <option value="private schools">Private Schools</option> */}
                  <option value="malls">Malls</option>
                  <option value="hospitality">Hospitality</option>
                  <option value="diplomatic">Diplomatic</option>
                  <option value="government">Government</option>
                </select>
                <small className="text-danger">
                  {touched.industry && errors.industry}
                </small>
              </CFormGroup>
              <CFormGroup>
                {values.industry !== "" ? (
                  <select
                    className="custom-select-new"
                    // name="ccmonth"
                    value={values.subIndustry}
                    onChange={handleChange}
                    name="subIndustry"
                  >
                    <option value="">Select type</option>
                    {values.industry == "malls"
                      ? mallOptions.map((item) => (
                          <option value={item}>{item}</option>
                        ))
                      : values.industry == "business"
                      ? businessOptions.map((item) => (
                          <option value={item}>{item}</option>
                        ))
                      : values.industry == "hospitality"
                      ? hospitalityOptions.map((item) => (
                          <option value={item}>{item}</option>
                        ))
                      : values.industry == "diplomatic"
                      ? diplomatOptions.map((item) => (
                          <option value={item}>{item}</option>
                        ))
                      : values.industry == "government"
                      ? governmentOptions.map((item) => (
                          <option value={item}>{item}</option>
                        ))
                      : null}
                  </select>
                ) : null}

                <small className="text-danger">
                  {touched.subIndustry && errors.subIndustry}
                </small>
              </CFormGroup>
              <CFormGroup>
                <label className="custom-label-new">Organisation Address</label>
                <input
                  className="custom-input-new"
                  type="text"
                  placeholder="Organisation Address"
                  name="orgAddress"
                  value={values.orgAddress}
                  onChange={handleChange}
                />
                <small className="text-danger">
                  {touched.orgAddress && errors.orgAddress}
                </small>
              </CFormGroup>

              <CFormGroup>
                <label className="custom-label-new">Phone Number</label>
                <input
                  className="custom-input-new"
                  type="tel"
                  pattern="[0-9]*"
                  placeholder="Enter Phone Number"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                />
                <small className="text-danger">
                  {touched.phone && errors.phone}
                </small>
              </CFormGroup>
            </>
          )}

          {step === 2 && (
            <>
              <CFormGroup>
                <label className="custom-label-new">Email</label>
                <input
                  className="custom-input-new"
                  type="text"
                  placeholder="Enter your email address"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                />
                <small className="text-danger">
                  {touched.email && errors.email}
                </small>
              </CFormGroup>
              <CFormGroup>
                <label className="custom-label-new">Password</label>
                <input
                  className="custom-input-new"
                  type="password"
                  placeholder="Enter Your password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                />
                <small className="text-danger">
                  {touched.password && errors.password}
                </small>
              </CFormGroup>
              <CFormGroup>
                <label className="custom-label-new">Confirm Password</label>
                <input
                  className="custom-input-new"
                  type="password"
                  placeholder="Enter Your password"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleChange}
                />
                <small className="text-danger">
                  {touched.confirmPassword && errors.confirmPassword}
                </small>
              </CFormGroup>{" "}
            </>
          )}

          <div
            className="d-flex align-items-center"
            style={{
              marginTop: "40px",
            }}
          >
            <CustomNewButton
              type="submit"
              disabled={loading}
              text={loading ? "Loading..." : step === 1 ? "Next" : "Register"}
            ></CustomNewButton>

            <div
              style={{
                width: "140px",
                marginLeft: "120px",
                textAlign: "end",
                fontSize: "15px",
                fontWeight: "600",
              }}
            >
              Step {step} of 2
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default P4bRegComps;
