import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userType: "",
  profile: {},
  regType: "PGO",
  isShowOtp: false,
  regEmail: "",
};

export const userSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    updateUserProfile: (state, action) => {
      state.profile = action.payload;
    },
    updateRegType: (state, action) => {
      state.regType = action.payload;
    },
    updateShowOtp: (state, action) => {
      state.isShowOtp = action.payload;
    },
    updateRegEmail: (state, action) => {
      state.regEmail = action.payload;
    },
  },
});

export const {
  updateUserProfile,
  updateShowOtp,
  updateRegType,
  updateRegEmail,
} = userSlice.actions;

export default userSlice.reducer;
