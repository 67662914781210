import { combineReducers } from "redux";
import servicesReducer from "./slice/serviceSlice";
import csrReducer from "./slice/csrSlice";
import dashboardReducer from "./slice/dashboardSlice";
import userSlice from "./slice/userSlice";

export const rootReducer = combineReducers({
  servicesReducer,
  csrReducer,
  dashboardReducer,
  userSlice,
});
