import React from "react";
import { useHistory } from "react-router-dom";
import { CCard, CCardBody, CCol, CContainer, CRow } from "@coreui/react";

const PartnersTerm = () => {
  const history = useHistory();
  return (
    <CContainer>
      <CRow className="justify-content-center my-5">
        <CCol lg="9">
          <span className="back-button" onClick={() => history.goBack()}>
            BACK
          </span>
          <CCard className="terms-styles">
            <CCardBody>
              <div className="registerLogo">
                <img
                  src="/backgrounds/pooploggLogo.png"
                  alt="main-logo"
                  height="70px"
                  width="auto"
                />
              </div>
              <h2 className="my-3 font-weight-light">
                Truck Partner Terms of Use{" "}
              </h2>
              <p>
                These Truck Partner Terms of Use (the Agreement) are a legally
                binding contract between the Truck Partner (Truck Partner) and
                Pooplogg Limited , whose registered address is First floor, 23A
                Marina (Mamman Kontagora House) Lagos (Pooplogg).
              </p>
              <div>
                <div className="mt-5">
                  <h4 className="font-weight-light">
                    Pooplogg only provides to Truck Partner the Services as set
                    out in this Agreement, and does not itself provide sewage
                    evacuation services.{" "}
                  </h4>
                  <p>
                    Please read the terms and conditions of this Agreement
                    carefully. Certain features of the Services may be subject
                    to additional guidelines, policies, terms or rules which may
                    now be communicated to the Truck Partner from time to time,
                    including the Privacy Policy, Pooplogg Service Charter,
                    Truck Eligibility Requirements and Driver Eligibility
                    Requirements which will be provided to Truck Partner. All
                    such additional guidelines, policies, terms and rules are
                    incorporated by reference into this Agreement. In order to
                    use the Services, Truck Partner must agree to the terms of
                    this Agreement. Upon Truck Partner’s acceptance (electronic
                    or otherwise) of this Agreement, Truck Partner agrees to be
                    bound by the terms of this Agreement.
                  </p>
                  <h2>Truck Partner agrees as follows;</h2>

                  <div>
                    <h4 className="font-weight-bolder">1. SERVICES</h4>
                    <p>a. Pooplogg provides the following services:</p>
                    <div className="ml-4">
                      <p>
                        i. it makes available the Platform to Truck Partner and
                        Truck Partner Personnel;{" "}
                      </p>
                      <p>
                        ii. it facilitates the matching of persons (Customers)
                        who require the emptying of sewage from soakaways or
                        septic tanks (Sewage Evacuation) with Truck Partner
                        Personnel who are available to evacuate sewage;{" "}
                      </p>
                      <p>
                        iii. it may provide lead generation services to Truck
                        Partner and Truck Partner Personnel in the form of
                        orders made by Customers; and
                      </p>
                      <p>
                        iv. it acts as a collection agent on behalf of Truck
                        Partner for the collection of payment for the Sewage
                        Evacuation, (collectively, the Services).
                      </p>
                    </div>
                    <p>
                      b. Truck Partner acknowledges and agrees that Pooplogg is
                      a technology services provider and does not carry out
                      Sewage Evacuation.{" "}
                    </p>
                  </div>

                  <div>
                    <h4 className="font-weight-bolder">2. THE TRUCK(S) </h4>
                    <div className="ml-4">
                      <p>
                        a. Truck Partner can only register a Truck to a Driver
                        if the Truck meets the Truck Eligibility Requirements.
                        Pooplogg has the sole and absolute discretion to
                        determine whether a Truck meets the Truck Eligibility
                        Requirements and whether it will allow a Truck to be
                        registered on the Platform.{" "}
                      </p>
                      <p>
                        b. Truck Partner Personnel or Driver must not select a
                        Truck registered to their Driver Account if the Truck
                        that they select for the purposes of utilising the
                        Services does not meet the Truck Eligibility
                        Requirements at that time.
                      </p>
                      <p>
                        c. Truck Partner represents and warrants that at the
                        time of registration and while remaining registered,
                        including each time a Truck Partner Personnel or Driver
                        selects a Truck for the purpose of utilising the
                        Services, that:
                      </p>
                      <div className="ml-4">
                        <p>
                          i. each Truck registered to the Truck Partner or
                          Driver Account continues to meet the Truck Eligibility
                          Requirements;{" "}
                        </p>
                        <p>
                          ii. Each Driver registered to a Driver Account meets
                          and continues to meet the Driver Eligibility
                          Requirements;
                        </p>
                        <p>
                          iii. Truck Partner is the owner or lessee, or is
                          otherwise in lawful possession, of the Truck and has
                          all necessary rights and Consents (as applicable) to
                          provide Sewage Evacuation; and iv. Truck Partner will
                          provide any information, as and when it occurs, that
                          may affect compliance with the Truck Eligibility
                          Requirements.
                        </p>
                      </div>
                      <p>
                        d. Pooplogg has the right to conduct an inspection
                        (including spot checks) of Truck(s) and Truck Partner
                        Personnel to verify that the Truck and Truck Partner
                        Personnel are complying with the terms of this Agreement
                        (including the review of any licences, permits or other
                        information required to fulfil the Driver Eligibility
                        Requirements, Truck Eligibility Requirements or any
                        applicable Law).
                      </p>
                    </div>
                  </div>

                  <div>
                    <h4 className="font-weight-bolder">3. TRUCK PARTNER </h4>
                    <div className="ml-4">
                      <p>
                        {" "}
                        a. The Truck Partner shall be deemed to be bound to
                        comply with the Pooplogg Service Charter which shall be
                        updated from time to time at Pooplogg’s sole discretion
                        without prior notice to the Truck Partner
                      </p>
                      <p>
                        b. Nothing in this clause 3 is intended to exclude the
                        operation of any other clause of this Agreement.
                      </p>
                      <p>
                        {" "}
                        c. Truck Partner must procure that all its Drivers who
                        are intending to access the Platform, enter into and
                        comply with the Driver Eligibility Requirements.
                      </p>
                      <p>
                        {" "}
                        d. Truck Partner will be solely responsible for all
                        Truck Partner Personnel and Driver who provide Sewage
                        Evacuation, including responsibility for wages,
                        benefits, taxes and expenses, insurance and any other
                        obligations under applicable Laws.
                      </p>
                      <p>
                        e. Truck Partner must ensure that all Trucks that are
                        registered on the Platform comply, and continue to
                        comply during the Term, with the Truck Eligibility
                        Requirements.
                      </p>
                      <p>
                        f. Without limiting clause 3(d), Truck Partner must
                        procure that all Truck Partner Personnel comply with the
                        Use of the Platform and Services as contained in these
                        Terms of Use.
                      </p>
                      <p>
                        {" "}
                        g. Truck Partner must comply, and ensure the compliance
                        of Truck Partner Personnel, with all applicable Laws,
                        including any Laws in relation to the use of heavy
                        vehicles or in relation to the use of mobile phones
                        while driving, fatigue management, speeding, medical
                        fitness, occupational health and safety and any other
                        Laws that may impact upon the ability to comply with
                        this Agreement.
                      </p>
                      <p>
                        h. Truck Partner shall deploy comprehensive asset care
                        and safety to ensure maximum truck reliability and
                        availability.{" "}
                      </p>
                      <p>
                        i. Ensure that the Truck(s) and Truck Partner Personnel
                        discharge only at a Discharge Site as advised by
                        Pooplogg from time to time.
                      </p>
                      <p>
                        {" "}
                        j. Be responsible for the repair of the tracking device
                        installed on the Truck(s) by Pooplogg in the event that
                        such damage is caused by the wilful action or negligence
                        of the Truck Partner or Truck Partner Personnel.
                      </p>
                      <p>
                        {" "}
                        k. Truck Partner must obtain and maintain all Consents
                        necessary to enable it to perform its obligations under
                        this Agreement without infringing any Law or the rights
                        of any person.
                      </p>
                      <p>
                        l. Truck Partner shall procure the compliance of the
                        Truck Partner Personnel with all Pooplogg requirements
                        on the collection, safe storage and delivery to Pooplogg
                        of any data regarding the Customer or the Customer’s
                        premises.
                      </p>
                      <p>
                        {" "}
                        m. Deploy the Truck on the Platform for provision of the
                        Service and in order to meet the minimum asset
                        utilisation as notified by Pooplogg from time to time.
                      </p>
                      <p>
                        {" "}
                        n. Conform with Pooplogg’s environmentally friendly
                        policies as contained in the Service Charter. Truck
                        Partners are advised to carefully acquaint themselves
                        with the Pooplogg Service Charter
                      </p>
                      <p>
                        {" "}
                        o. Where required by Pooplogg, immediately provide the
                        Truck(s) to Pooplogg for branding with the livery of
                        Pooplogg or for advertisements on reasonable terms to
                        protect the Pooplogg brand and the Truck.Truck Partner
                        agrees that Pooplogg is under no obligation to brand a
                        Truck.
                      </p>
                      <p>
                        {" "}
                        p. Truck Partner must not transfer, assign, charge,
                        subcontract or otherwise dispose of this Agreement, or
                        any of its rights or obligations arising under it,
                        without Pooplogg's prior written consent.
                      </p>
                      <p>
                        {" "}
                        q. Pooplogg's consent under clause 3(p) will not relieve
                        Truck Partner of its obligations to Pooplogg under this
                        Agreement, and Truck Partner will be fully responsible
                        to Pooplogg for the acts or omissions of its
                        subcontractors, contractors, assigns and all their
                        employees, as if it were the acts and omissions of Truck
                        Partner.
                      </p>
                      <p>
                        r. Provide an acceptable bank account to Pooplogg for
                        receipt of all payments from Pooplogg. s. Provide any
                        security deposit to Pooplogg as may be required to
                        enable the Truck Partner’s Truck(s) be eligible for cash
                        transactions.
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <h4 className="font-weight-bolder">4. POOPLOGG</h4>
                  <div className="ml-4">
                    <p>
                      4.1 At Pooplogg’s discretion, Pooplogg may install a
                      compatible tracking device on the Truck(s) on terms
                      specified in the Truck Eligibility Requirements.
                    </p>
                    <p>
                      4.2 Truck Partner Personnel shall be provided with
                      periodic and relevant training opportunities to build
                      capability in customer service and asset care.
                    </p>
                    <p>
                      4.3 Reasonable support shall be provided by Pooplogg to
                      the Truck Partner to enable successful use of the
                      Platform.
                    </p>
                    <p>
                      4.4 Effect remittance to the Truck Partner’s designated
                      bank account of Truck Partner’s portion of monies
                      collected from customers for any Services successfully
                      executed by the Truck Partner’s Truck(s).
                    </p>
                  </div>
                </div>
                <div>
                  <h4 className="font-weight-bolder">
                    5. PAYMENT, INVOICE AND TAX
                  </h4>
                  <div className="ml-4">
                    <p className="font-weight-bolder">5.1. Payment </p>
                    <p>
                      {" "}
                      a. Pooplogg as Truck Partner’s collection agent, shall
                      collect from Customer and remit to the Truck Partner’s
                      designated bank account, all monies earned by the Truck
                      Partner’s Truck timeously as agreed.
                    </p>
                    <p>
                      b. In consideration of Pooplogg's provision of the
                      Platform and linking the Customer with the Truck Partner’s
                      Truck(s) for the provision of the Services, Truck Partner
                      unequivocally consents to Pooplogg keeping as a fee any
                      payments made by the Customer above the fee accepted by
                      the Driver.
                    </p>
                    <p>
                      {" "}
                      c. Truck Partner agrees to pay Pooplogg any sums specified
                      in the Truck Eligibility Requirements . Truck Partner
                      authorises Pooplogg to set off or deduct any sum of money
                      specified in the Truck Eligibility Requirements from any
                      amounts payable to Truck Partner as fee earned from the
                      provision of any Service on the Platform. Truck Partner
                      acknowledges that the amount charged as this fee may
                      change from time to time. Pooplogg shall send prior
                      notification to Truck Partner and/or Driver of each such
                      change.
                    </p>
                    <p>
                      {" "}
                      d. Cash collected by Truck Partner Personnel from
                      Customer: To be eligible for cash transactions, Pooplogg
                      may require Truck Partner to open a cash security deposit
                      account under the control of Pooplogg or lodge an agreed
                      amount of money with Pooplogg. In the event that such an
                      account is opened or such amount is deposited with
                      Pooplogg, Truck Partner hereby grants to Pooplogg the
                      right to set-off any amounts owing to Pooplogg in respect
                      of cash transactions in which the Truck Partner’s
                      Personnel has collected cash from the Customer
                    </p>
                    <p className="font-weight-bolder">5.2. Invoice </p>
                    <p className="ml-4">
                      After each completed Sewage Evacuation order , Pooplogg
                      shall generate an invoice consisting of the fee accepted
                      by the Driver and other relevant information for record
                      keeping purposes.
                    </p>

                    <p className="font-weight-bolder">5.3. Tax </p>
                    <p className="ml-4">
                      Truck Partner and Truck Partner Personnel acknowledge that
                      it is the obligation of Truck Partner to fully comply with
                      all tax obligations that arise from the applicable Law in
                      relation to providing the Sewage Evacuation. Pooplogg may
                      in its reasonable discretion and based on applicable tax
                      law, collect and remit taxes resulting from the provision
                      by Truck Partner and Truck Partner personnel of Sewage
                      Evacuation and/or provide any of the relevant tax
                      information directly to the applicable governmental tax
                      authorities on behalf of Truck Partner.
                    </p>
                  </div>
                </div>
                <div>
                  <h4 className="font-weight-bolder"> 6. TERRITORY </h4>
                  <div className="ml-4">
                    <p>
                      6.1. Every Truck onboarded unto the Platform will be
                      assigned to any Territory or number of Territories as may
                      be determined by Pooplogg from time to time.
                    </p>
                    <p>
                      6.2. Pooplogg reserves the sole discretion to register a
                      Truck in any Territory
                    </p>
                    <p> 6.3. Territory will be as determined by Pooplogg.</p>
                  </div>
                </div>
                <div>
                  <h4 className="font-weight-bolder">7. TIME OF SERVICE</h4>
                  <div className="ml-4">
                    <p>
                      a. Pooplogg operates a 24-hour business environment on the
                      Platform.
                    </p>
                    <p>
                      {" "}
                      b. Truck Partner agrees to make the Truck and Truck
                      Partner Personnel available for use within the work hours
                      as may be determined by Pooplogg.
                    </p>
                    <p>
                      {" "}
                      c. Truck Partner accepts that Pooplogg is under no
                      obligation to offer any Service request by a Customer to
                      any Truck and/or Driver within any specific time of the
                      day.
                    </p>
                    <p>
                      {" "}
                      d. Truck Partner for himself and on behalf of Driver
                      unequivocally accepts that time is of the essence in
                      respect of all Service orders accepted by a Driver.
                    </p>
                    <p>
                      e. Truck Partner and Driver unequivocally acknowledge that
                      Pooplogg shall have the right to withdraw any offer to
                      render a Service to a Customer on the Platform if in the
                      opinion of Pooplogg the Driver has not commenced the
                      journey to the Customer’s promises within a reasonable
                      time to meet the declared time preferences of the
                      Customer.
                    </p>
                  </div>
                </div>
                <div>
                  <h4 className="font-weight-bolder">
                    8. TITLE PROPERTY AND RISK
                  </h4>
                  <div className="ml-4">
                    <p>
                      8.1 Nothing in this Agreement shall be construed as
                      transferring the title, ownership of and interest in the
                      Truck(s) to Pooplogg.
                    </p>
                    <p>
                      8.2 The Truck(s) shall remain at the sole risk of the
                      Truck Partner for the duration of this Agreement while the
                      Truck(s) is/are used to provide the Service.
                    </p>
                  </div>
                </div>
                <div>
                  <h4 className="font-weight-bolder">9. Privacy</h4>
                  <div className="ml-4">
                    <p>
                      {" "}
                      i) Pooplogg may collect personal information about Truck
                      Partner (or, its Personnel including Drivers) in order to
                      provide the Services and for other purposes set out in the
                      Privacy Policy.
                    </p>
                    <p>
                      ii) Truck Partner agrees and acknowledges that personal
                      information may be disclosed to third parties (including
                      to Customers and to assist in the resolution of any
                      dispute between Truck Partner, Driver and Customer), and
                      that the Privacy Policy sets out how Pooplogg collects,
                      uses, stores and discloses personal information and the
                      consequences if that information is not provided.
                    </p>
                    <p>
                      iii) The Privacy Policy sets out the manner in which Truck
                      Partner (or, its Personnel including Drivers) may access
                      personal information, seek correction of personal
                      information or complain about a breach of the Nigeria Data
                      Protection Regulation 2019.
                    </p>
                    <p>
                      {" "}
                      iv) Without limiting any other part of this clause 9, any
                      information collected by Pooplogg under this Agreement,
                      including any information required to assess initial or
                      ongoing compliance with the Driver Eligibility
                      Requirements or the Truck Eligibility Requirements, will
                      be collected, used and disclosed in accordance with the
                      Privacy Policy.
                    </p>
                  </div>
                </div>
                <div>
                  <h4 className="font-weight-bolder">10 UPDATES </h4>
                  <div className="ml-4">
                    <p>
                      a) Pooplogg may make Updates available to Truck Partner or
                      Truck Partner Personnel from time to time. Truck Partner
                      and Truck Partner Personnel must install any Updates as
                      soon as reasonably practicable upon receipt. Once
                      installed, Updates will be deemed to form part of the
                      Platform.
                    </p>
                    <p>
                      {" "}
                      b) To the extent permitted by Law, Pooplogg shall not be
                      liable for any failure of the Platform or the Services to
                      operate in accordance with this Agreement, or to otherwise
                      meet any warranties or representations set out in this
                      Agreement or required under Law, unless Truck Partner and
                      Truck Partner Personnel have installed all Updates
                      pursuant to this clause.
                    </p>
                  </div>
                </div>
                <div>
                  <h4 className="font-weight-bolder">
                    11 INTELLECTUAL PROPERTY RIGHTS{" "}
                  </h4>

                  <div className="ml-4">
                    <p className="font-weight-bold">
                      11.1 Platform and Services{" "}
                    </p>
                    <p>
                      (a) (Ownership) The parties acknowledge that all rights,
                      title and interest in the Platform and Services, including
                      in any improvements to the Platform and Services
                      (including any Intellectual Property Rights in the
                      Platform and Services, and any improvements to them)
                      remain with Pooplogg and/or its licensors at all times and
                      nothing in this Agreement is intended to transfer such
                      right, title or interest to Truck Partner.
                    </p>
                    <p>
                      (b) (Licence) Subject to clause 11.1(c) and Truck
                      Partner's compliance with the terms of this Agreement,
                      Pooplogg grants to Truck Partner a non-transferable and
                      non-exclusive licence to install, view and use the
                      Platform for personal and business purposes (to the extent
                      that the business purpose is solely and directly related
                      to the provision by Driver of Sewage Evacuation to
                      Customer) only.
                    </p>
                    <p>
                      (c) The licence granted under clause 11.1(b) does not
                      include a right to sub-license or otherwise provide the
                      Platform or Services to any third party.
                    </p>
                    <p>
                      (d) The Licence herein granted is revoked automatically
                      and simultaneously on termination of this Agreement. After
                      termination Truck Partner, Truck Partner Personnel and
                      Driver shall stop using the Platform and Pooplogg is
                      entitled to block and delete Driver Account without prior
                      notice.
                    </p>
                    <p>
                      {" "}
                      (e) Pooplogg may give Truck Driver tags, labels, stickers
                      or other signs that refer to Pooplogg or otherwise
                      indicate that Truck Partner is using the Pooplogg
                      Platform. Pooplogg grants to Truck Partner a
                      non-exclusive, non-transferable licence to use such signs
                      only for the purpose of indicating that Truck Partner is
                      providing Sewage Evacuation via the Platform. After
                      termination of this agreement, Truck Partner must
                      immediately remove and discard any signs that refer to
                      Pooplogg or its brand.{" "}
                    </p>
                    <p>
                      (f) All copyrights and trademarks related to Pooplogg,
                      including source codes, databases, logos and visual
                      designs are owned by Pooplogg and protected by copyright,
                      trademark and/or trade secret laws and international
                      treaties and convention provisions. Use of the App does
                      not confer on Truck Partner any rights of ownership to any
                      intellectual Property belonging to Pooplogg.
                    </p>
                  </div>

                  <div className="ml-4">
                    <p className="font-weight-bolder"> 11.2 Feedback </p>
                    <p>
                      a) Truck Partner agrees and acknowledges that any
                      feedback, suggestions, bug reports, system errors and
                      other information or ideas in relation to the Platform or
                      the Services (Feedback) may be used by Pooplogg in any
                      manner it deems appropriate (and will not be regarded as
                      being confidential or proprietary information).
                    </p>
                    <p>
                      (b) Truck Partner will ensure that any existing or future
                      Intellectual Property Rights in any Feedback vest in
                      Pooplogg absolutely. Truck Partner agrees to assign, and
                      procure the assignment of, such Intellectual Property
                      Rights in any Feedback to Pooplogg immediately on their
                      creation.
                    </p>
                  </div>
                  <div className="ml-4">
                    <p className="font-weight-bolder">11.3 User Content</p>
                    <p>
                      (a) Truck Partner agrees and acknowledges that it is
                      solely responsible for any User Content. To the extent
                      permitted by Law, Pooplogg will not be responsible for any
                      User Content and provides no guarantees regarding the
                      accuracy, currency, suitability or quality of any User
                      Content.
                    </p>
                    <p>
                      (b) Truck Partner grants Pooplogg a perpetual, worldwide,
                      transferable, royalty-free, non-exclusive right to access,
                      use, adapt, modify, reproduce, reformat, transform,
                      process, commercialise and exploit, and create Derivative
                      Materials from User Content to the extent necessary to
                      undertake such activities.
                    </p>
                    <p>
                      c) Pooplogg may disclose User Content to third party
                      service providers, if Pooplogg merges with or is acquired
                      by a third party, or to comply with applicable Laws.
                      Pooplogg may also use User Content to investigate or
                      prevent any breach or potential breach of any applicable
                      Law or this Agreement.
                    </p>
                  </div>
                  <div className="ml-4">
                    <p className="font-weight-bolder">
                      {" "}
                      11.4 Data and Derivative Materials{" "}
                    </p>
                    <p>
                      (a) Truck Partner grants Pooplogg a perpetual, worldwide,
                      transferable, royalty-free non-exclusive right to access,
                      use, adapt, modify, reproduce, reformat, transform,
                      process, commercialise and exploit, and create Derivative
                      Materials from, the Data to the extent necessary to
                      undertake such activities.
                    </p>
                    <p>
                      {" "}
                      (b) Truck Partner agrees and acknowledges that Pooplogg
                      intends to use and/or aggregate the Data in conjunction
                      with other information collected or obtained by Pooplogg,
                      and Truck Partner agrees that Pooplogg is permitted to
                      make full use of, commercialise and exploit the Data for
                      those purposes.
                    </p>
                    <p>
                      (c) Truck Partner will ensure that any existing or future
                      Intellectual Property Rights in any Derivative Materials
                      immediately vest in Pooplogg absolutely. Truck Partner
                      agrees to assign, and procure the assignment of, such
                      Intellectual Property Rights in any Derivative Materials
                      to Pooplogg immediately on their creation.
                    </p>
                    <p>
                      d) Without limiting the foregoing, Truck Partner agrees
                      and acknowledges that Pooplogg may disclose Data and
                      Derivative Materials to any third party in its sole
                      discretion.
                    </p>
                  </div>
                </div>
                <div>
                  <h4 className="font-weight-bolder">
                    12 THIRD PARTY CONTENT{" "}
                  </h4>
                  <p className="ml-4">
                    The Platform and/or Services may contain links to third
                    party websites, services or advertisements for third parties
                    (Third Party Content). Those links are provided for
                    convenience and may not remain current or be maintained.
                    Unless expressly stated otherwise, Pooplogg does not endorse
                    and is not responsible for any Third Party Content and has
                    no control over or rights in Third Party Content.
                  </p>
                </div>
                <div>
                  <h4 className="font-weight-bolder">13 WARRANTIES</h4>
                  <div className="ml-4">
                    <p className="font-weight-bold">
                      13.1 Each party warrants to the other party that:
                    </p>
                    <div className="ml-3">
                      <p>
                        {" "}
                        a) It has the full right, corporate control and lawful
                        authority to execute and deliver this Agreement and
                        enter into and perform and observe the agreements and
                        covenants on its part contained herein and has taken all
                        necessary action to authorise the entry into and
                        performance of this Agreement.
                      </p>
                      <p>
                        {" "}
                        b) The entry into and performance by it of the
                        transactions contemplated by this Agreement do not
                        conflict with any law or regulation applicable to it,
                        its constitutional documents, or any document that is
                        binding upon it or any of its assets.
                      </p>
                      <p>
                        {" "}
                        C) All authorisations required in connection with its
                        entry into and performance under, and the validity and
                        enforceability of this Agreement and the transactions
                        contemplated hereby have been obtained or effected (as
                        appropriate) and are in full force and effect.
                      </p>
                    </div>
                    <p className="font-weight-bold">
                      {" "}
                      13.2 The Truck Partner warrants to Pooplogg that as at the
                      time of entering this Agreement and for the duration:
                      13.2.1 The Truck(s) have been legally acquired and that
                      all legal permits, licenses or approvals for utilising the
                      Truck(s) for Sewage Evacuation in the Territory have been
                      obtained by the Truck Partner and shall remain valid for
                      the period the Truck(s) are used to secure any jobs on the
                      Pooplogg platform.
                    </p>{" "}
                    <p className="font-weight-bold">
                      13.2.2 The data of the Truck Partner’s Personnel are
                      authentic.
                    </p>{" "}
                    <p className="font-weight-bold">
                      {" "}
                      13.2.3 The Truck Partner has appropriately paired the
                      Driver and the Truck in the data provided to Pooplogg to
                      ensure that no Driver is allowed to drive a Truck he has
                      not been pre-approved to drive without the prior approval
                      of Pooplogg.
                    </p>{" "}
                    <p className="font-weight-bold">
                      13.2.4 Each Driver provided by the Truck Partner is an
                      agent of the Truck Partner and is deemed to have the Truck
                      Partner’s mandate to use the Pooplogg Platform to receive
                      and accept offers to provide the Services on the Platform
                      with the understanding that any such jobs are
                      unequivocally jobs belonging to the Truck Partner.
                    </p>{" "}
                    <p className="font-weight-bold">
                      13.2.5 Truck Partner’s Driver(s) shall co-operate with
                      Pooplogg Personnel to ensure that Customers are served in
                      compliance with the Pooplogg Service Charter.
                    </p>
                    <p className="font-weight-bold">
                      {" "}
                      13.2.6 Truck Partner Personnel willl only discharge waste
                      at the Discharge Site directed by Pooplogg.
                    </p>
                    <p className="font-weight-bold">
                      {" "}
                      13.2.7 each Truck used to provide Sewage Evacuation is in
                      good working condition and order and has been serviced in
                      accordance with all applicable Law;
                    </p>
                    <p className="font-weight-bold">
                      {" "}
                      13.2.8 Truck Partner Personnel will take all reasonable
                      steps to prevent breaches of any applicable Law; and
                    </p>
                    <p className="font-weight-bold">
                      13.2.9 it has the knowledge and ability to liaise and
                      interact with Customers in a customer focused manner.
                    </p>
                  </div>
                </div>
                <div>
                  <h4 className="font-weight-bolder">14 INDEMNITIES</h4>
                  <div className="ml-4">
                    <p className="font-weight-bold ">
                      14.1 Indemnity by Pooplogg{" "}
                    </p>
                    <p>
                      Pooplogg shall defend, hold harmless and indemnify Truck
                      Partner from and against any Loss suffered or incurred by
                      Truck Partner arising out of or in connection with any
                      claim that the Services infringe any Intellectual Property
                      Right or other right of any third party, except to the
                      extent that such Loss is caused or contributed to by an
                      act or omission of Truck Partner or Truck Partner
                      Personnel.
                    </p>
                  </div>

                  <div className="ml-4">
                    <p className="font-weight-bold">
                      14.2 Indemnity by Truck Partner{" "}
                    </p>
                    <p>
                      {" "}
                      Truck Partner shall defend, hold harmless and indemnify
                      Pooplogg from and against any Loss suffered or incurred by
                      Pooplogg arising out of or in connection with:
                    </p>
                    <p>
                      (a) any breach of this Agreement or any applicable Laws;{" "}
                    </p>
                    <p>
                      {" "}
                      (b) the death or injury of any person arising out of or
                      otherwise in connection with any negligence or wrongful
                      act or omission of Truck Partner or Truck Partner
                      Personnel;
                    </p>
                    <p>
                      {" "}
                      <p></p>
                      (c) any fraud (including intentional and/or fraudulent
                      misrepresentation), criminal activity, negligence or
                      wilful misconduct of Truck Partner or Truck Partner
                      Personnel;
                    </p>
                    <p>
                      {" "}
                      (d) any claim by any person (including Truck Partner) in
                      connection with any Feedback, User Content or data used or
                      disclosed by Pooplogg in connection with this Agreement;
                    </p>
                    <p>
                      {" "}
                      (e) any claim by any person (including Truck Partner) that
                      is connected with use by Truck Partner or Truck Partner
                      Personnel of the Services;
                    </p>
                    <p>
                      (f) any claim by any person that is connected with the
                      provision by Truck Partner or Driver of Sewage Evacuation,
                      including a Customer, arising out of or in connection with
                      the provision by Truck Partner or driver of a Sewage
                      Evacuation; or
                    </p>
                    <p>
                      (g) any loss or damage to property arising out of or
                      otherwise in connection with any wrongful act or omission
                      of Truck Partner or Truck Partner Personnel.
                    </p>
                  </div>
                </div>
                <div>
                  <h4 className="font-weight-bolder">
                    15 LIMITATION OF LIABILITY{" "}
                  </h4>
                  <div className="ml-4">
                    <p>
                      (a) To the extent permitted by Law, and subject to clause
                      15(d), in no event will the aggregate liability of
                      Pooplogg for any Loss, direct or otherwise, exceed the
                      Liability Cap, regardless of the cause or form of action.
                    </p>
                    <p>
                      {" "}
                      (b) To the extent permitted by Law, under no circumstances
                      will Pooplogg be liable for any Consequential Loss.
                    </p>
                    <p>
                      (c) Pooplogg does not guarantee the submission of requests
                      by Customers and can in no way be considered as a person
                      acting on behalf of or in the name of a Customer. Pooplogg
                      shall not be liable for any loss or damage that Truck
                      Partner or Truck Partner Personnel may incur as a result
                      of actions or non-actions of a Customer or its
                      representatives.
                    </p>
                    <p>
                      (d) The limitations and exclusions in this clause 15 shall
                      apply whether the action, claim or demand arises from
                      breach of contract, tort (including negligence) or under
                      any other theory of liability.
                    </p>
                    <p>
                      (e) Clause 15(a) does not apply to, and shall not limit,
                      any party's liability:
                    </p>
                    <div className="ml-3">
                      <p>
                        (i) for death or personal injury caused by that party or
                        its Personnel;
                      </p>
                      <p>
                        {" "}
                        (ii) for fraud (including fraudulent misrepresentation)
                        or;
                      </p>
                      <p>(iii) under any indemnity given in this Agreement.</p>
                    </div>
                  </div>
                </div>
                <div>
                  <h4 className="font-weight-bolder">
                    16 RELATIONSHIP OF THE PARTIES
                  </h4>
                  <div className="ml-4">
                    <p>
                      16.1 Nothing in this Agreement is intended to, or shall be
                      deemed to, establish any partnership or joint venture
                      between the Parties, constitute any Party the agent of
                      another Party, or authorise any Party to make or enter
                      into any commitments for or on behalf of any other party.
                    </p>
                    <p>
                      {" "}
                      16.2 It is agreed that for the duration of this Agreement,
                      the Truck Partner shall be an independent contractor and
                      not the servant, agent, partner or legal representative of
                      Pooplogg for any purpose whatsoever. The Truck Partner
                      shall bear exclusive responsibility for any liability
                      incurred by Truck Partner Personnel in connection with the
                      Service performed in furtherance of this Agreement.{" "}
                    </p>
                    <p>
                      {" "}
                      16.3 Each Party confirms it is acting on its own behalf
                      and not for the benefit of any other person.
                    </p>
                  </div>
                </div>

                <div>
                  <h4 className="font-weight-bolder">17 ASSIGNMENT</h4>
                  <div className="ml-4">
                    <p>
                      Truck Partner must not transfer, assign, charge,
                      subcontract or otherwise dispose of this Agreement, or any
                      of its rights or obligations arising under it, without
                      Pooplogg's prior written consent.
                    </p>{" "}
                  </div>{" "}
                </div>

                <div>
                  <h4 className="font-weight-bolder">18 FORCE MAJEURE</h4>
                  <div className="ml-4">
                    <p>
                      Neither party shall be in breach of this Agreement nor
                      liable for delay in performing, or failure to perform, any
                      of its obligations under this agreement if such delay or
                      failure result from events, circumstances or causes beyond
                      its reasonable control. Provided the affected party
                      notifies the other Party within a reasonable time of the
                      event of force majeure, the affected party shall be
                      entitled to a reasonable extension of the time for
                      performing such obligations.
                    </p>{" "}
                  </div>{" "}
                </div>

                <div>
                  <h4 className="font-weight-bolder">19 VARIATION </h4>
                  <div className="ml-4">
                    <p>
                      (a) Pooplogg may amend these terms and conditions from
                      time to time to reflect changes in market conditions,
                      changes in technology used to provide the products and
                      services under this Agreement, changes in payment methods,
                      changes in relevant Laws and regulatory requirements and
                      changes in the capabilities of Pooplogg's system.
                    </p>
                    <p>
                      (b) Pooplogg will notify Truck Partner of any amendment to
                      this Agreement in writing. Truck Partner must accept the
                      amended terms in order to continue its use of the
                      Services. If Truck Partner does not agree to the amended
                      terms, Truck Partner will be deemed to have exercised its
                      right to terminate the Agreement pursuant to clause 20(f)
                      below.
                    </p>
                  </div>{" "}
                </div>

                <div>
                  <h4 className="font-weight-bolder">20. TERMINATION </h4>
                  <div className="ml-4">
                    <p>
                      {" "}
                      (a) (for cause) Either party may terminate this Agreement
                      with immediate effect by giving written notice to the
                      other party at any time if the other party: (i) breaches
                      any warranty in this Agreement or any other provision of
                      this Agreement which is incapable of being remedied, or
                      where the breach is capable of being remedied, fails to
                      remedy the breach within seven days after receiving
                      written notice from the terminating party requiring it to
                      do so; or (ii) on the occurrence of an Insolvency Event.
                    </p>
                    <p>
                      (b) (termination for failure to comply with requirements)
                      Without limiting clause 20(a)(i), Pooplogg may suspend
                      Truck Partner's or Truck Partner’s Personnel’s access to
                      the Services or terminate this Agreement with immediate
                      effect if Truck Partner or Truck Partner’s Personnel
                      breaches any of the Driver Eligibility Requirements, or
                      Truck Eligibility Requirements, or this Agreement.
                    </p>
                    <p>
                      {" "}
                      (c) (termination for misrepresentation as to compliance
                      with requirements) Without limiting clause 20(a)(i),
                      Pooplogg may suspend Truck Partner or Driver's access to
                      the Services on the Platform or terminate this Agreement
                      with immediate effect if Truck Partner or Driver makes any
                      intentional or fraudulent misrepresentation in connection
                      with the Driver Eligibility Requirements or Truck
                      Eligibility Requirements.
                    </p>
                    <p>
                      {" "}
                      (d) (suspension or termination for unacceptable Driver
                      Ratings) Pooplogg may suspend Driver's access to the
                      Services on the Platform or terminate this Agreement with
                      immediate effect
                    </p>
                    <p>
                      {" "}
                      (e) (termination for Regulatory Event) If a Regulatory
                      Event that, in the reasonable opinion of Pooplogg, may
                      affect the subject matter of this Agreement occurs or is
                      likely to occur during the Term, Pooplogg may immediately
                      suspend the performance of this Agreement or terminate
                      this Agreement without liability to Truck Partner or to
                      Pooplogg.
                    </p>
                    <p>
                      (f) (termination for refusal of new terms) If Truck
                      Partner or any Truck Partner’s Personnel elects not to
                      accept variations to these terms made pursuant to clause
                      19, Truck Partner will be deemed to have terminated this
                      Agreement with immediate effect.
                    </p>
                    <p>
                      (g) (termination for convenience) Either party may
                      terminate this Agreement for convenience and without
                      incurring liability to the other party at any time by
                      giving at least 30 days' written notice to the other
                      party.
                    </p>
                  </div>{" "}
                </div>

                <div>
                  <h4 className="font-weight-bolder">21. WAIVER </h4>
                  <div className="ml-4">
                    <p>
                      The failure of Pooplogg at any time to require performance
                      by Truck Partner of any provision hereof shall in no way
                      affect the right of Pooplogg to require any performance
                      which may be due thereafter pursuant to such provision,
                      and the waiver by Pooplogg of any breach of any provision
                      of this Agreement shall not be construed or held to be a
                      waiver of any subsequent breach of such provisions.
                    </p>{" "}
                  </div>
                </div>

                <div>
                  <h4 className="font-weight-bolder">
                    22. DISPUTE RESOLUTION{" "}
                  </h4>
                  <div className="ml-4">
                    <p>
                      22.1. Any dispute, controversy or claim arising out of or
                      in connection with this Agreement, or the validity,
                      termination, interpretation, performance or
                      non-performance hereof (including the determination of the
                      scope of this Agreement to arbitrate) or the relationship
                      between the Parties created by this Agreement, which
                      cannot be resolved by discussion in good faith between the
                      Parties, shall be settled by binding arbitration in
                      accordance with this Clause. Such arbitration shall be
                      conducted in accordance with the Arbitration and
                      Conciliation Act, (Cap. A18, Laws of the Federation of
                      Nigeria, 2004) or successor legislation in force as at the
                      date of commencement of such arbitration. The number of
                      arbitrators shall be three (3). The Parties shall each
                      appoint one (1) arbitrator, and the two (2) appointed
                      shall select a third (3) arbitrator who shall serve as
                      chairperson/presiding arbitrator of the arbitration panel.
                      In the event that the two appointed arbitrators fail to
                      agree on the choice of the third arbitrator within ten
                      (10) days of their respective appointments, either Party
                      shall no later than seven (7) days after the expiration of
                      the ten (10) days apply to the Chairman of the Chartered
                      Institute of Arbitrators UK, Nigeria Branch to appoint the
                      third arbitrator, who shall serve as the
                      chairman/presiding arbitrator of the panel. The arbitral
                      proceedings shall be conducted in the English language and
                      the venue of the arbitration shall be Lagos, Nigeria,
                      unless another location is selected by mutual agreement of
                      the Parties.
                    </p>{" "}
                    <p>
                      22.2. The provisions of this clause shall survive
                      termination of this Agreement, howsoever caused.
                    </p>
                  </div>
                </div>

                <div>
                  <h4 className="font-weight-bolder">23. NOTICES</h4>
                  <div className="ml-4">
                    <p>
                      23.1. Pooplogg shall give notice in writing to Truck
                      Partner at the address supplied by Truck Partner in the
                      Truck Partner Account.
                    </p>{" "}
                    <p>
                      23.2. Truck Partner may give notice to Pooplogg by written
                      communication to Pooplogg's address at 1st Floor, 23A
                      Marina (Mamman Kontagora House), Lagos, Nigeria or by
                      e-mail to info@pooplogg.com
                    </p>
                  </div>
                </div>

                <div>
                  <h4 className="font-weight-bolder">24. ENTIRE AGREEMENT</h4>
                  <div className="ml-4">
                    <p>
                      This Agreement, together with the, Pooplogg Service
                      Charter, the Truck Eligibility Requirements, Driver
                      Eligibility Requirements, the Privacy Policy and all
                      additional guidelines, policies, terms or rules
                      communicated by Pooplogg to Truck Partner from time to
                      time shall constitute the entire Agreement between the
                      Parties, and supersedes all earlier representations,
                      warranties, covenants, conditions, agreements or
                      understandings by or between the parties in connection
                      with its subject matter. None of the parties has relied on
                      or is relying on any other representations, warranties,
                      covenants, conditions, agreements or understandings in
                      entering into this Agreement and completing the
                      transactions contemplated by it.
                    </p>{" "}
                  </div>
                </div>

                <div>
                  <h4 className="font-weight-bolder">25. GOVERNING LAW</h4>
                  <div className="ml-4">
                    <p>
                      The interpretation, validity and performance of this
                      Agreement and any dispute arising therefrom shall be
                      determined in accordance with the Laws of the Federal
                      Republic of Nigeria.
                    </p>{" "}
                  </div>
                </div>

                <div>
                  <h4 className="font-weight-bolder">26. GENERAL</h4>
                  <div className="ml-4">
                    <p className="font-weight-bold mb-2">
                      26.1. Further assurances{" "}
                    </p>
                    <p>
                      Each party agrees to do all things and execute all deeds,
                      instruments, transfers or other documents as may be
                      necessary or desirable to give full effect to the
                      provisions of this Agreement and the transactions
                      contemplated by it.
                    </p>{" "}
                  </div>
                  <div className="ml-4">
                    <p className="font-weight-bold mb-2">
                      26.2. Entire agreement{" "}
                    </p>
                    <p>
                      This Agreement contains the entire agreement between the
                      parties with respect to its subject matter. It sets out
                      the only conduct, representations, warranties, covenants,
                      conditions, agreements or understandings (collectively
                      Conduct) relied on by the parties and supersedes all
                      earlier Conduct by or between the parties in connection
                      with its subject matter. None of the parties has relied on
                      or is relying on any other Conduct in entering into this
                      Agreement and completing the transactions contemplated by
                      it.
                    </p>{" "}
                  </div>
                  <div className="ml-4">
                    <p className="font-weight-bold mb-2">26.3. Waiver </p>
                    <p>
                      No failure to exercise or delay in exercising any right,
                      power or remedy under this Agreement operates as a waiver.
                      A single or partial exercise or waiver of the exercise of
                      any right, power or remedy does not preclude any other or
                      further exercise of that or any other right, power or
                      remedy. A waiver is not valid or binding on the party
                      granting that waiver unless made in writing.
                    </p>{" "}
                  </div>
                  <div className="ml-4">
                    <p className="font-weight-bold mb-2">
                      26.4. Remedies cumulative{" "}
                    </p>
                    <p>
                      The rights, powers and remedies provided to a party in
                      this Agreement are in addition to, and do not exclude or
                      limit, any right, power or remedy provided by law or
                      equity or any agreement.{" "}
                    </p>{" "}
                  </div>
                  <div className="ml-4">
                    <p className="font-weight-bold mb-2">26.5. No merger </p>
                    <p>
                      The rights and obligations of the parties will not merge
                      on the completion of any transaction contemplated by this
                      Agreement. They will survive the execution and delivery of
                      any assignment or other document entered into for the
                      purpose of implementing a transaction.
                    </p>{" "}
                  </div>
                  <div className="ml-4">
                    <p className="font-weight-bold mb-2">26.6. Severability </p>
                    <p>
                      Any provision of this Agreement which is prohibited or
                      unenforceable in any jurisdiction is ineffective as to
                      that jurisdiction to the extent of the prohibition or
                      unenforceability. That does not invalidate the remaining
                      provisions of this Agreement nor affect the validity or
                      enforceability of that provision in any other
                      jurisdiction.
                    </p>{" "}
                  </div>
                  <div className="ml-4">
                    <p className="font-weight-bold mb-2">
                      26.7. Costs and duty
                    </p>
                    <p>
                      Each party must bear its own costs arising out of the
                      negotiation, preparation and execution of this Agreement.
                    </p>{" "}
                  </div>
                  <div className="ml-4">
                    <p className="font-weight-bold mb-2">
                      26.8. Governing law and jurisdiction
                    </p>
                    <p>
                      This Agreement and, to the extent permitted by law, all
                      related matters including non-contractual matters, is
                      governed by the laws of the Federal Republic of Nigeria.
                      In relation to such matters each party irrevocably accepts
                      the jurisdiction of courts with jurisdiction there and
                      waives any right to object to the venue on any ground.
                    </p>{" "}
                  </div>
                  <div className="ml-4">
                    <p className="font-weight-bold mb-2">26.9. Counterparts</p>
                    <p>
                      This Agreement may be executed in any number of
                      counterparts. All counterparts will be taken to constitute
                      one agreement.
                    </p>{" "}
                  </div>
                </div>

                <div>
                  <h4 className="font-weight-bolder">
                    27. DEFINITIONS AND INTERPRETATION{" "}
                  </h4>
                  <div className="ml-4">
                    <p className="font-weight-bold mb-2">27.1. Definitions</p>
                    <p>
                      The following definitions apply unless the context
                      requires otherwise.
                    </p>{" "}
                    <p>
                      <strong>App </strong> means the mobile application
                      described as the "Pooplogg App" (as updated from time to
                      time) or any other application used to provide the
                      Services.
                    </p>
                    <p>
                      <strong> Consents </strong>
                      means any licences, clearances, permissions, certificates,
                      permits, authorities, declarations, exemptions, waivers,
                      approvals or consents.{" "}
                    </p>
                    <div className="ml-4">
                      <p className="mb-0">
                        <strong>Consequential Loss </strong>
                        means any:
                      </p>
                      <p>
                        a. loss of profits, loss of income or revenue, loss of
                        data, loss of or damage to reputation, loss of or damage
                        to goodwill, loss of business opportunities (including
                        opportunities to enter into or complete arrangements
                        with third parties), loss of management time, damage to
                        credit rating, or loss of business; and
                      </p>
                      <p>
                        {" "}
                        b. any loss, not arising naturally (that is according to
                        the usual course of things), from the relevant breach,
                        whether or not such loss is reasonably supposed to have
                        been in the contemplation of both parties, at the time
                        they made the Agreement, as the probable result of the
                        relevant breach.
                      </p>
                    </div>{" "}
                    <p>
                      <strong>Customer </strong>has the meaning given in clause
                      1(a)(ii).
                    </p>
                    <p>
                      <strong> Data </strong>
                      means data (excluding Personal Information) that Truck
                      Partner or Truck Partner’s Personnel provide to Pooplogg
                      any other means or that is otherwise obtained by or on
                      behalf of Pooplogg or via the Platform or by any other
                      means.
                    </p>
                    <p>
                      <strong> Derivative Materials </strong>
                      means materials, data and insights derived or created by
                      or on behalf of Pooplogg or its Personnel in accordance
                      with clause 10.4, which are based on, or created or
                      derived from, the Data.
                    </p>
                    <p>
                      <strong>Device </strong>
                      means the device provided and used by Truck Partner or
                      Truck Partner Personnel to access the Platform or the App.
                    </p>
                    <p>
                      <strong>Discharge Site </strong>
                      means any physical location to which evacuated Sewage must
                      be discharged by Driver in order to complete a Job.
                    </p>
                    <p>
                      <strong>Dispute</strong>
                      means a dispute between the parties arising in connection
                      with this Agreement
                    </p>
                    <p>
                      <strong>Driver </strong>
                      means a licensed driver who is registered to a Driver
                      Account belonging to a Truck Partner and who meets the
                      Driver Eligibility Requirements.
                    </p>
                    <p>
                      <strong>Driver Account </strong>
                      means an Account the Truck Partner registers in respect of
                      a Driver on the Platform in order to access and use the
                      Platform.
                    </p>
                    <p>
                      <strong>Driver Eligibility Requirements </strong>
                      means the qualification requirements for Driver, as
                      communicated by Pooplogg from time to time to Truck
                      Partner.
                    </p>
                    <p>
                      <strong>Feedback </strong>
                      has the meaning given in clause 11.2(a).
                    </p>
                    <p>
                      <strong>Force Majeure Event </strong>
                      means an event or cause beyond the reasonable control of
                      the party claiming force majeure. It includes each of the
                      following, to the extent it is beyond the reasonable
                      control of that party:
                    </p>
                    <div className="ml-4">
                      <p>
                        {" "}
                        a. act of God, lightning, storm, flood, fire, earthquake
                        or explosion;
                      </p>
                      <p>b. strike, lockout or other labour difficulty;</p>
                      <p>
                        {" "}
                        c. act of public enemy, war (declared or undeclared),
                        terrorism, sabotage, blockade, revolution, riot,
                        insurrection, civil commotion, epidemic;
                      </p>
                      <p>
                        {" "}
                        d. the effect of any applicable laws, orders, rules or
                        regulations of any government or other competent
                        authority;
                      </p>
                      <p>
                        e. embargo, power or water shortage, lack of
                        transportation, lack of public or private
                        telecommunications networks; and
                      </p>
                      <p>
                        f. breakage or accident or other damage to machinery.
                      </p>
                    </div>{" "}
                    <p>
                      <strong>Governmental Agency </strong>
                      means any government or any governmental,
                      semi-governmental or judicial entity or authority. It also
                      includes any self-regulatory organisation established
                      under statute or any stock exchange.
                    </p>
                    <p>
                      <strong>Intellectual Property Rights </strong>
                      means all industrial and intellectual property rights of
                      any kind including but not limited to copyright (including
                      rights in computer software), trade mark, service mark,
                      design, patent, trade secret, semiconductor or circuit
                      layout rights, trade, business, domain or company names,
                      moral rights, rights in Confidential Information, know how
                      or other proprietary rights (whether or not any of these
                      are registered and including any application, or right to
                      apply, for registration) and all rights or forms of
                      protection of a similar nature or having equivalent or
                      similar effect to any of these which may subsist anywhere
                      in the world.
                    </p>
                    <p>
                      <strong>Law </strong>
                      means all laws including rules of common law, principles
                      of equity, statutes, regulations, executive orders,
                      ordinances, by-laws, rules, guidelines, regulations,and
                      mandatory codes of conduct..
                    </p>
                    <p>
                      <strong>Liability Cap </strong>
                      means the amount equal to the fees paid to Driver in the
                      preceding three month period from the time the event
                      occurred and resulting in a Loss.
                    </p>
                    <p>
                      <strong>Loss </strong>
                      means any loss, liability, claim, damage, cost, charge,
                      expense or diminution in value, however arising, and
                      whether present or future, fixed or unascertained, actual
                      or contingent.
                    </p>
                    <p>
                      <strong>Personal Information </strong>
                      means "personal information" as defined in the National
                      Data Protection Regulations 2019 that either party has
                      collected, received or otherwise has access to in
                      connection with this Agreement.
                    </p>
                    <p>
                      <strong> Personnel </strong>
                      means in respect of a person any employee, contractor,
                      servant, agent, or other person under the person's direct
                      or indirect control and includes any subcontractors.
                    </p>
                    <p>
                      <strong>Platform </strong>
                      means each or all of the Pooplogg proprietary website(s),
                      mobile applications, the App, software, hardware devices
                      and communications infrastructure.
                    </p>
                    <p>
                      <strong> Privacy Policy </strong>
                      means the privacy policy of Pooplogg available at
                      www.pooplogg.com as amended from time to time.
                    </p>
                    <p className="mb-0">
                      <strong>Regulatory Event </strong>
                      means:
                    </p>
                    <div className="ml-4">
                      <p>
                        {" "}
                        a. an amendment, repeal or change in Law, the enactment
                        of a new Law, or a change in the interpretation or
                        application of a Law; or
                      </p>
                      <p>
                        b. a direction, notice or order of a Governmental Agency
                        which is binding on Pooplogg or Truck Partner.
                      </p>
                    </div>
                    <p>
                      <strong>Sewage Evacuation </strong>
                      means the evacuation of sewage by Driver for Customer and
                      includes safe transport and discharge at the Disposal Site
                      instructed by Pooplogg.
                    </p>
                    <p>
                      <strong>Tax </strong>
                      includes any tax, levy, impost, deduction, charge, rate,
                      duty, compulsory loan or withholding that is levied or
                      imposed by a Governmental Agency, and any related
                      interest, penalty, charge, fee or other amount.
                    </p>
                    <p>
                      <strong>Territory </strong>
                      means any geographical demarcation by Pooplogg of an area
                      within a city and allocated to a Truck for logistic
                      efficiency.
                    </p>
                    <p>
                      <strong>Third Party Content </strong>
                      has the meaning given in clause 11.
                    </p>
                    <p>
                      <strong>Truck </strong>
                      means any vehicle registered by a Truck Partner on the
                      Platform which is linked to a Driver Account, and which
                      meets the Truck Eligibility Requirements.
                    </p>
                    <p>
                      <strong>Truck Eligibility Requirements </strong>
                      means the onboarding requirements for Truck, as
                      communicated by Pooplogg from time to time to Truck
                      Partner.
                    </p>
                    <p>
                      <strong>Truck Partner Account</strong>
                      means an account registered to the Truck Partner on the
                      Platform.
                    </p>
                    <p>
                      <strong>Truck Partner </strong>
                      means an individual or entity who accepts the terms of
                      this Agreement and employs, contracts, procures or makes
                      other arrangements with Truck Partner Personnel in
                      relation to their use of their use of this Platform.
                    </p>
                    <p>
                      <strong>Truck Partner Personnel</strong>
                      means Driver(s) and/or Driver’s assistant(s) who are
                      employed by, contract with or make other arrangements with
                      Truck Partner in relation to their use of the Platform for
                      the provision of the Services.
                    </p>
                    <p>
                      <strong>Update</strong>
                      means any update or upgrade to the Platform issued by
                      Pooplogg from time to time.
                    </p>
                    <p>
                      <strong>User Content </strong>
                      means any and all information, data and other content that
                      a Driver or Customer submits to, or uses with, the App or
                      Services.
                    </p>
                  </div>

                  <div>
                    <p className="font-weight-bold">26.2. Interpretation </p>
                    <p>
                      The following rules apply unless the context requires
                      otherwise:{" "}
                    </p>
                    <div className="ml-3">
                      <p>
                        a. headings are for convenience only and do not affect
                        interpretation;
                      </p>
                      <p>
                        b. the singular includes the plural, and the converse
                        also applies;
                      </p>
                      <p>
                        c. nothing in this Agreement is to be interpreted
                        against a party solely on the ground that the party put
                        forward this Agreement or a relevant part of it;{" "}
                      </p>
                      <p>
                        d. if a word or phrase is defined, its other grammatical
                        forms have a corresponding meaning;
                      </p>
                      <p>
                        e. a reference to a person includes a corporation,
                        trust, partnership, unincorporated body or other entity,
                        whether or not it comprises a separate legal entity;
                      </p>
                      <p>
                        f. a reference to a clause is a reference to a clause
                        ofthis Agreement;
                      </p>
                      <p>
                        g. a reference to an agreement or document (including a
                        reference to this Agreement) is to the agreement or
                        document as amended, supplemented, novated or replaced,
                        except to the extent prohibited by this Agreement or
                        that other agreement or document;
                      </p>
                      <p>
                        h. a reference to a party to this Agreement or another
                        agreement or document includes the party's successors,
                        permitted substitutes and permitted assigns (and, where
                        applicable, the party's legal personal representatives);
                      </p>
                      <p>
                        i. a reference to legislation or to a provision of
                        legislation includes a modification or re-enactment of
                        it, a legislative provision substituted for it and a
                        regulation or statutory instrument issued under it;
                      </p>
                      <p>
                        {" "}
                        j. a reference to writing includes any method of
                        representing or reproducing words, figures, drawings or
                        symbols in visible or tangible form;
                      </p>
                      <p>
                        k. a reference to conduct includes an omission,
                        statement or undertaking, whether or not in writing;
                      </p>
                      <p>
                        {" "}
                        l. a reference to an agreement includes any undertaking,
                        deed, agreement and legally enforceable arrangement,
                        whether or not in writing, and a reference to a document
                        includes an agreement (as so defined) in writing and any
                        certificate, notice, instrument and document of any
                        kind;
                      </p>
                      <p>
                        {" "}
                        m. mentioning anything after includes, including, for
                        example, or similar expressions, does not limit what
                        else might be included;
                      </p>
                      <p></p>
                      <p></p>
                      <p>
                        n. a reference to Naira is to Nigerian currency or such
                        other currency which is legal tender in the Federal
                        Republic of Nigeria; and
                      </p>
                      <p> o. a month means a calendar month.</p>
                    </div>
                  </div>
                </div>

                {/* <div>
                  <h4 className="font-weight-bolder">14 INDEMNITIES</h4>
                  <p className="font-weight-bold ml-4">
                    14.1 Indemnity by Pooplogg{" "}
                  </p>
                  <p className="ml-4">
                    Pooplogg shall defend, hold harmless and indemnify Truck
                    Partner from and against any Loss suffered or incurred by
                    Truck Partner arising out of or in connection with any claim
                    that the Services infringe any Intellectual Property Right
                    or other right of any third party, except to the extent that
                    such Loss is caused or contributed to by an act or omission
                    of Truck Partner or Truck Partner Personnel.
                  </p>
                </div> */}
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  );
};

export default PartnersTerm;
