import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allServices: [],
  selectedServices: [],
  serviceCreated: false,
};

export const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    handleAllServices: (state, action) => {
      state.allServices = action.payload;
    },
    handleSelectedServices: (state, action) => {
      state.selectedServices = action.payload;
    },
    handleCreatedService: (state, action) => {
      state.serviceCreated = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  handleAllServices,
  handleSelectedServices,
  handleCreatedService,
} = servicesSlice.actions;

export default servicesSlice.reducer;
